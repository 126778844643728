.g3Logo {
  height: 120px;
  width: 191px;
  flex: 0 0 auto;
  background-image: url('/public/assets/Gatethree.svg');
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
}

.main-login-button {
  width: 191px;
}

.trouble-signing-in {
  text-align: center;
  margin-top: 45px;
  span {
    margin: 0px;
    font-size: 14px;
    color: white;
  }

  a {
    color: #ffc107;
    cursor: pointer;
  }
}

.background-test {
  background: #00141d;
}

.gate3_picture {
  height: 100%;
  width: 100%;
  background: url('https://caas.azureedge.net/phoenix/backgrounds/Ottawa Background.png');
  background-size: cover;
  background-color: rgb(16, 49, 74);
}

#umbra-iframe {
  width: 100% !important;
  height: 100vh;
  border: none !important;
}

.login-selector {
  height: 40px;
  width: 100%;
  position: relative;
  margin-bottom: 15px;
}

.login-selector__wrapper {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
}

.login-selector__container {
  height: 100%;
  position: relative;
  display: inline-block;
  width: fit-content;
}

.login-selector__container select {
  outline: none !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: 125px;
}

.login-selector select {
  height: 100%;
  font-size: 16px;
  background: #069 !important;
  padding: 0 30px;
  border-radius: 5px;
  border: none;
  color: white;
  width: 140px;
  text-align: center;
}

.login-selector__button-inner {
  display: inline-flex;
  align-items: center;
}

.login-selector__icon {
  background-color: white;
  -webkit-mask-image: url('https://radix-cdn.gatethree.io/bestpractices-images/Down.svg');
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-image: url('https://radix-cdn.gatethree.io/bestpractices-images/Down.svg');
  mask-repeat: no-repeat;
  mask-position: center;
  height: 16px;
  width: 16px;
  background-size: contain;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.login-selector__drawer {
  width: 100%;
  background: white;
  margin-top: 40px;
  overflow: hidden;
  border-radius: 5px;
}

.login-selector__option {
  height: 60px;
  width: 100%;
  padding: 10px;
  display: inline-flex;
  align-items: center;
}

.login-selector__option:hover {
  background: rgba(0, 0, 0, 0.1);
}

.login-selector__option p {
  margin: 0;
  font-family: 'Acumin Pro';
  font-size: 32px;
}

@media only screen and (orientation: landscape) {
  .login-selector {
    height: 45px;
 }

  .login-selector select {
    font-size: 17px;
    padding: 0 15px;
    border-radius: 5px;
 }
 
  .login-selector__icon {
    height: 17px;
    width: 17px;
    position: absolute;
    top: 16px;
 }
}
